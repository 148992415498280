<template>
  <v-container class="subscriber">
    <v-form ref="search_sub" lazy-validation>
      <v-text-field
        @keypress.enter.prevent="search()"
        :rules="rules"
        required
        :label="$t('search_id')"
        v-model="searchTxt"
        single-line
        hide-details
        filled
        rounded
        class="mx-auto mt-5 searchsub"
        style="max-width: 500px"
        clearable
        :disabled="loading"
      >
        <template v-slot:append>
          <v-btn
            @click="search()"
            color="primary"
            large
            rounded
            :disabled="loading"
          >
            {{ $t("search_txt") }}
          </v-btn>
        </template>
      </v-text-field>
    </v-form>
    <div class="text-center mt-5" v-if="!subscriber.id">
      <search-s-v-g style="width: 300px" />
    </div>

    <v-row class="mt-14" justify="center" v-else>
      <v-col cols="12">
        <v-card class="text-center pb-3" elevation="5" rounded="lg">
          <v-card-title>
            <v-avatar
              color="primary white--text"
              size="90"
              class="text-h5 mx-auto elevation-5"
              style="margin-top: -58px"
              >{{ subscriber.name[0] }}
            </v-avatar>
          </v-card-title>
          <v-card-text>
            <v-row class="text-h6 text-start">
              <v-col cols="12" md="6">
                {{ $t("name") }} : {{ subscriber.name }}
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("smart_card") }} : {{ subscriber.id }}
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("mobile") }} : {{ subscriber.invoices[0].mobile }}
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("email") }} : {{ subscriber.invoices[0].email }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-subheader v-if="subscriber.invoices" style="place-content: end">
          {{ $t("about_results", { count: subscriber.invoices.length }) }}
        </v-subheader>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $t("package_name") }}</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("next_payment") }}</th>
                <th>{{ $t("contract_start") }}</th>
                <th>{{ $t("contract_expiry") }}</th>
                <th>{{ $t("point_sales") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(invoice, i) in subscriber.invoices"
                :key="i"
                :style="i === 0 ? 'background-color: rgba(0,0,0,0.1)' : ''"
              >
                <td>{{ invoice.package_name }}</td>
                <td>{{ invoice.date }}</td>
                <td>{{ invoice.next_payment }}</td>
                <td>{{ invoice.contract_start_date }}</td>
                <td>{{ invoice.contract_end_date }}</td>
                <td>{{ invoice.point_sales }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mySub } from "@/api/URLs";
import i18n from "@/plugins/i18n";
import SearchSVG from "@/components/widgets/SearchSVG";
export default {
  name: "MySubscribe",
  components: { SearchSVG },
  data: () => ({
    loading: false,
    subscriber: {},
    searchTxt: "",
    rules: [(value) => !!value || i18n.t("this_required")],
  }),
  created() {},
  methods: {
    search() {
      if (this.$refs.search_sub.validate()) {
        this.loading = true;
        let self = this;
        this.$http({
          method: "post",
          url: mySub,
          data: { id: self.searchTxt },
        }).then(function (response) {
          if (response.data.success && response.data.data) {
            self.subscriber = response.data.data;
          } else {
            self.$store.commit("showToast", "no_found");
          }
          self.loading = false;
        });
      }
    },
  },
};
</script>

<style>
.subscriber .v-input__append-inner {
  margin-top: 0 !important;
  align-self: center;
}
.subscriber .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-inline-end: 12px !important;
}
</style>
